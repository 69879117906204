import ModuleArticle from '@/store/moduleArticle'
import { Component, Vue, Prop } from 'vue-property-decorator'
import '@toast-ui/editor/dist/toastui-editor-viewer.css'

import { Viewer } from '@toast-ui/vue-editor'
import matrixModule from '@/store/matrix'

@Component({
  components: {
    Viewer,
  },
})
export default class index extends Vue {
  matrizName = ''
  quadrantName = ''
  article: any = {}
  mounted() {
    this.article = ModuleArticle.article
    this.matrizName = matrixModule.matrix?.name as string
    this.quadrantName = this.$t(
      `CMS.${matrixModule.quadrantName as string}`,
    ) as string
  }
}
